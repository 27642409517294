import React from 'react';
import { PlaceType } from '../../types/PlaceType';
import { DetailedPlace } from '../../types/DetailedPlace';
import { OverlayView } from '@react-google-maps/api';
import PlaceMarkerCard from './PlaceMarkerCard';

type Props = {
  place: DetailedPlace;
  onMouseOver: (DetailedPlace) => void;
  onMouseOut: (DetailedPlace) => void;
}

const resolveIcon = (type: PlaceType) => {
  switch (type) {
    case PlaceType.fine_dining:
      return 'img/fine-dining-icon.png';
    case PlaceType.comfort_food:
    default:
      return 'img/comfort-food-icon.png';
  }
}

const PlaceMarker = (props: Props) => {
  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <OverlayView
      position={props.place.location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        onMouseOver={() => { console.log(true); setShowDetails(true) }}
        onMouseOut={() => { console.log(false); setShowDetails(false) }}
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -100%)',
          cursor: 'pointer',
          height: '40px',
          width: '40px',
        }}
      >
        <img src={resolveIcon(props.place.type)} style={{ height: '100%', width: '100%', zIndex: 1 }} alt="Custom Marker" />
        {showDetails && (<PlaceMarkerCard place={props.place} />)}
      </div>
    </OverlayView>
  );
}

export default PlaceMarker;
