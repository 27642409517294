import React, { useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader, InfoBox } from '@react-google-maps/api';
import { DetailedPlace } from '../../types/DetailedPlace';
import PlaceMarker from './PlaceMarker';
import { Loading } from '@nextui-org/react';

type Props = {
  places: DetailedPlace[];
}

const Map = (props: Props) => {
  const [map, setMap] = useState(null);
  const [hoveredPlace, setHoveredPlace] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [center, setCenter] = useState({
    lat: 41.38775102154063,
    lng: 2.173528417509599,
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBBPWOIzjFi9absrcTVSGu9VR66eR3WOvY"
  })

  const onLoad = useCallback((map) => {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  const noBusinessMarkersStyle = [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    }
  ];

  const onMouseOverMarker = (place: DetailedPlace) => {
    setHoveredPlace(place);
  };

  const onMouseOutOfMarker = () => {
    setHoveredPlace(null);
  };

  const markers = props.places.map(place => <PlaceMarker
    place={place}
    onMouseOver={onMouseOverMarker}
    onMouseOut={onMouseOutOfMarker}
  />)

  return isLoaded ? (
    <div style={{ flex: 1 }}>
      <GoogleMap
        zoom={14}
        center={center}
        onUnmount={onUnmount}
        onLoad={onLoad}
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
        options={{ styles: noBusinessMarkersStyle }}
      >
        {markers}
        {selectedPlace && (
          <InfoBox
            position={selectedPlace.googleData.geometry.location}
            onCloseClick={() => {
              setSelectedPlace(null);
            }}
          >
            <div style={{ backgroundColor: 'lightcoral' }}>
              <h3>{selectedPlace.googleData.name}</h3>
            </div>
          </InfoBox>
        )}
      </GoogleMap>
    </div>) : <Loading />
}

export default React.memo(Map)
