
import { Card, Container, Grid, Row, Text } from '@nextui-org/react';
import React from 'react';
import { DetailedPlace } from '../../types/DetailedPlace';

type Props = {
  place: DetailedPlace;
}

const mapPriceLevel = (priceLevel: number) => {
  switch (priceLevel) {
    case 1:
      return '€';
    case 2:
      return '€€';
    case 3:
      return '€€€';
    case 4:
      return '€€€€';
    default:
      return '';
  }
}

const PlaceMarkerCard = (props: Props) => {
  return (
    <Container style={{
      width: '250px',
      height: '150px',
      zIndex: 100,
    }}>
      <Card isPressable>
        <Card.Body css={{ p: 0 }}>
          <Card.Image
            src={'img/fine-dining-example.jpeg'}
            objectFit="cover"
            width="100%"
            height={140}
            alt={props.place.name}
          />
        </Card.Body>
        <Card.Footer css={{ justifyItems: "flex-start" }}>
          <Row wrap="wrap" justify="space-between" align="center">
            <Text b>{props.place.name}</Text>
            <Text css={{ color: "$accents7", fontWeight: "$semibold", fontSize: "$sm" }}>
              {mapPriceLevel(props.place.priceLevel)}
            </Text>
          </Row>
        </Card.Footer>
      </Card>
    </Container>
  )
};

export default PlaceMarkerCard;