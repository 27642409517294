import React, { useEffect, useState } from 'react'
import { NextUIProvider, Grid } from '@nextui-org/react';
import Header from './components/header/Header'
import * as crudClient from './client/crud';
import Map from './components/map/'

function App() {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    crudClient.getPlaces()
      .then(setPlaces)
      .catch(error => console.error(error));
  }, []);

  return (
    <NextUIProvider>
      <Grid.Container justify="center" style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
      }}>
        <Header />
        <Map places={places} />
      </Grid.Container>
    </NextUIProvider >
  );
}

export default App;
