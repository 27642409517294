import Axios from 'axios';
import { DetailedPlace } from '../types/DetailedPlace';

const client = Axios.create({
  baseURL: 'http://api.allora.guide',
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json',
  },
});

export const getPlaces = async (): Promise<DetailedPlace[]> => {
  try {
    const response = await client.get('/api/places');
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
